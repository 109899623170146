<script>
    import { tweened } from "svelte/motion";
    import { cubicOut } from "svelte/easing";


    let position = tweened(5, {
        duration: 200,
        easing: cubicOut,
    });
</script>

<div class="bx--menu-link" 
    on:click 
    style="padding-left:{$position}px" 
    on:mouseenter={() => position.set(20)}
    on:mouseout={() => position.set(5)}
    on:blur={console.log("")}
>
    <slot/>
</div>