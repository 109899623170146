<script>
    import { links } from "../file_variables"
    import { LinkButton } from "../components"
</script>

<div class="bx--tracks-heading">
    External Links
</div>
<div class="bx--all-tracks">
{#each links as link}
    <LinkButton name={link.name} src={link.url}/>
{/each}
</div>