const tracks = [
  {
    name: "Look What I Made Me Do",
    url: "tracks/1-look.mp3",
  },
  {
    name: "Face It",
    url: "tracks/2-face.mp3",
  },
  {
    name: "Concrete",
    url: "tracks/3-concrete.mp3",
  },
  {
    name: "Mantra",
    url: "tracks/4-mantra.mp3",
  },
  {
    name: "Body (feat. Japes)",
    url: "tracks/5-body.mp3",
  },
  {
    name: "Mould Me (feat. Kiidotcom.)",
    url: "tracks/6-mould.mp3",
  },
];

export { tracks };

/*
Each track should be listed as follows in the above array 

  {
    name: "Track Name",
    url: "tracks/track_name.mp3",
  },

*/
