<script>
    export let name
    export let src

    import { tweened } from "svelte/motion";
    import { cubicOut } from "svelte/easing";

    let position = tweened(1, {
    duration: 200,
    easing: cubicOut,
  });

  function downloadFile(src, name) {
    const a = document.createElement("a");
    a.href = src;
    a.setAttribute("download", name);
    a.click();
  }
</script>

<div class="bx--button" 
    style="transform: scale({$position})"
    on:mouseenter={() => position.set(1.1)}
    on:mouseout={() => position.set(1)}
    on:blur={console.log("")}
    on:click={()=>downloadFile(src, name)}

>
    {name}
</div>