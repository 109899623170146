const downloads = [
  {
    name: "Mould Me - .wav",
    url: "/downloads/mouldme.wav",
  },
  {
    name: "Mantra - .wav",
    url: "/downloads/mantra.wav",
  },
];

export { downloads };

/*
Each download should be listed as follows in above array
{
  name: "Name of download",
  url: "downloads/name_of_file.extension",
}
*/
