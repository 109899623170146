const images = [
  {
    alt: "CLAY",
    url: "images/1.jpg",
  },
  {
    alt: "FORM",
    url: "images/2.jpg",
  },
  {
    alt: "DENSITY",
    url: "images/3.jpg",
  },
];

export { images };

/* 
Each image should be listed as the following in above array 

  {
    alt: "image alt text",
    url: "images/imagename.extension",
  },

*/
