<script>
    export let currentPlayingTrack
    export let trackPlaying
    let trackCheck = ""

    let playButton = '/icons/play.svg';
	let pauseButton = '/icons/pause.svg';
	let replay = '/icons/replay.svg';


    let duration;
	let durInt;
	let durFix = 0;
	let currentTime = 0;
    let paused = true

    function durationFix() {
		if (duration != undefined) {
			durInt = duration;
			durFix = secondsToHms(duration);
		}
	}

    function secondsToHms(d) {
		var m = Math.floor((d % 3600) / 60);
		var s = Math.floor((d % 3600) % 60);
		var mDisplay = m > 0 ? m + ':' : '0:';
		var sDisplay = s > 0 ? (s < 10 ? '0' : '') + s : '00';
		return mDisplay + sDisplay;
	}

    function resetAudio() {
		paused = true;
		setTimeout(function () {
			currentTime = 0;
			paused = false;
		}, 200);
	}

    function resetDefaults() {
        currentTime = 0;
        paused = true
    }

    function checkTrack(name) {
        if (name.name == trackCheck) {
            console.log("passing")
        } else {
            trackCheck = name.name
            resetDefaults()
        }
    }

    $: duration, durationFix();
    $: currentPlayingTrack, checkTrack(currentPlayingTrack)
</script>

{#if trackPlaying == true}
    <div class="container">
        <div class="controls">
            <div class="top">
                <audio src={currentPlayingTrack.url} bind:duration bind:currentTime bind:paused />
                <p>{currentPlayingTrack.name}</p>
                <div class="slidecontainer">
                    <input
                        type="range"
                        min="0"
                        max={durInt}
                        bind:value={currentTime}
                        class="slider"
                        id="myRange"
                    />
                </div>
            </div>
            <div class="bottom">
                <button on:click={() => (paused = !paused)}>
                    <img class="control" src={paused ? playButton : pauseButton} alt="Play/Pause" />
                </button>

                <button on:click={() => resetAudio()}>
                    <img class="control" src={replay} alt="Replay" />
                </button>
            </div>
        </div>
        <div class="duration">
            <p class="dur">{secondsToHms(currentTime)}</p>
            <p class="dur">{durFix}</p>
        </div>
    </div>
{/if}

<style>
    .container {
        position: fixed;
        top: 0px;
        right: 0px;
		width: 50%;
		height: 100px;
		margin-top: 1rem;
        margin-right: 1rem;
		display: flex;
		align-items: center;
		border: 1px var(--highlight-color) solid;
		margin-top: 2rem;
        background-color: var(--background-color);
	}
	img {
		width: 100px;
		height: auto;
	}
	button {
		width: 10%;
		padding-left: 12px;
		background-color: rgba(128, 128, 128, 0);
		border: none;
	}
	.controls {
		width: 85%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		padding-left: 12px;
		padding-right: 12px;
		position: relative;
	}
	.control {
		width: 30px;
		height: 30px;
	}
	.duration {
		width: 15%;
		right: 12px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.top {
		width: 80%;
	}
	.bottom {
		width: 80%;
		display: flex;
		justify-content: space-evenly;
	}
	.dur {
		font-family: var(--body-font);
	}
	.slider {
		-webkit-appearance: none;
		appearance: none;
		width: 100%;
		height: 15px;
		background-color: rgba(0, 0, 0, 0);
		outline: none;
		border: 1px var(--highlight-color) solid;
		border-radius: 15px;
		opacity: 1;
		-webkit-transition: 0.2s;
		transition: opacity 0.2s;
	}
	.slider::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		width: 15px;
		height: 15px;
		border: 1px var(--highlight-color) solid;
		border-radius: 15px;
		background: var(--highlight-color);
		cursor: pointer;
	}
	.slider::-moz-range-thumb {
		width: 15px;
		height: 15px;
		border-radius: 15px;
		border: 1px var(--highlight-color) solid;
		background: var(--highlight-color);
		cursor: pointer;
	}
    p {
        color: var(--body-font-color);
        font-family: var(--body-font)
    }
</style>