<script>
    import { tracks, defaults } from "../file_variables"
    import { TrackButton } from "../components"

    export let trackPlaying
    export let currentPlayingTrack
    export let trackCheck

    function updateTrack(track) {
        stopTrack()
        setTimeout(function () {
			trackPlaying = true;
			currentPlayingTrack = track;
		}, 200);
    }

    function stopTrack() {
        trackPlaying=false
        currentPlayingTrack=""
    }

    $: currentPlayingTrack, trackCheck=currentPlayingTrack
</script>

<img class="bx--tracks-cover" src={defaults.cover_image} alt={defaults.release_name}/>
<div class="bx--tracks-heading">
    {defaults.release_name}
</div>
<div class="bx--all-tracks">
    {#each tracks as track }
        {#if track.name == currentPlayingTrack.name}
            <TrackButton on:click={()=>stopTrack()}>
                Stop
            </TrackButton>
        {:else}
            <TrackButton on:click={()=>updateTrack(track)}>
                {track.name}
            </TrackButton>
        {/if}
    {/each}
</div>
<div class="bx--info-container">
    Click to play
</div>