const videos = [
  {
    title: "Comf - Mould Me",
    videoId: "JOAy7AYtsM8",
  },
];

export { videos };

/* 
Each video should be listed as follows in the above array 

  {
    title: "video title",
    videoId: "videos/video_name.extension",
  },

*/
