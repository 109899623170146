const links = [
  {
    name: "Facebook",
    url: "https://www.facebook.com/COMFIBOY",
  },
  {
    name: "Instagram",
    url: "https://www.instagram.com/THOROUGHLYUSELESS/",
  },
  {
    name: "Twitter",
    url: "https://www.twitter.com/iamcomf/",
  },
  {
    name: "Presave Streaming",
    url: "https://distrokid.com/hyperfollow/comf/water",
  },
  {
    name: "Bandcamp Preorder",
    url: "https://comf.bandcamp.com/album/clay",
  },
];

export { links };

/*
  Each link should be listed as follows in above array
  {
    name: "Name of link",
    url: "https://www.link.com",
  }
  */
