<script>
    import { defaults, tracks, images, downloads, links, videos } from "../file_variables"
    import  MenuButton from "../components/MenuButton.svelte"

    export let selectedPage

    function selectPage(page) {
        selectedPage = page
    }
</script>

<div class="bx--menu-container">
    <div class="bx--menu-heading">
        {defaults.artist}
    </div>
    <div class="bx--menu-release-name">
        {defaults.release_name}
    </div>
    <div class="bx--menu-release-date">
        {defaults.release_date}
    </div>
    <div class="bx--menu-links">
        <MenuButton on:click={()=>selectPage(0)}>
            Bio
        </MenuButton>
        {#if tracks.length !== 0}
            <MenuButton on:click={()=>selectPage(1)}>
                Tracks
            </MenuButton>
        {/if}
        {#if images.length !== 0}
            <MenuButton on:click={()=>selectPage(2)}>
                Gallery
            </MenuButton>
        {/if}
        {#if videos.length !== 0}
            <MenuButton on:click={()=>selectPage(3)}>
                Videos
            </MenuButton>
        {/if}
        {#if links.length !== 0}
            <MenuButton on:click={()=>selectPage(4)}>
                External Media
            </MenuButton>
        {/if}
        {#if downloads.length !== 0}
            <MenuButton on:click={()=>selectPage(5)}>
                Downloads
            </MenuButton>
        {/if}
    </div>
</div>